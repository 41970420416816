import { MLink } from "@/components/MLink/MLink";
import { ExternalLinkIcon } from "@/components/icons/ExternalLinkIcon";
import clsx from "clsx";

export function MExternalLink({
  label,
  href,
  ariaLabel,
  className,
  locale,
  iconClassName,
}: {
  label: string;
  href: string;
  ariaLabel: string;
  className?: string;
  locale?: string;
  iconClassName?: string;
}) {
  return (
    <MLink
      href={href}
      ariaLabel={ariaLabel}
      className={clsx(className, "flex gap-3")}
      locale={locale}
    >
      <span>{label}</span>
      <ExternalLinkIcon width={24} height={24} className={iconClassName} />
    </MLink>
  );
}
