import { SVGProps, Ref, forwardRef, memo } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    ref={ref}
    {...props}
  >
    <path
      fill="#9DAAB4"
      fillRule="evenodd"
      d="M16.5 18.535c0-.612.334-1.168.827-1.53a9 9 0 1 0-10.655 0c.494.362.828.918.828 1.53 0 .947.768 1.715 1.715 1.715h5.57c.947 0 1.715-.768 1.715-1.715Zm3-8.785a7.487 7.487 0 0 1-3.061 6.046c-.803.59-1.439 1.564-1.439 2.74a.215.215 0 0 1-.215.214H12.75v-4.19l3.53-3.53a.75.75 0 1 0-1.06-1.06L12 13.19 8.78 9.97a.75.75 0 0 0-1.06 1.06l3.53 3.53v4.19H9.215A.215.215 0 0 1 9 18.535c0-1.175-.636-2.148-1.439-2.739A7.5 7.5 0 1 1 19.5 9.75Z"
      clipRule="evenodd"
    />
    <path
      fill="#3A5569"
      fillRule="evenodd"
      d="M7.5 22.5a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5a.75.75 0 0 1-.75-.75Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const Memo = memo(ForwardRef);
export { Memo as LightsOn };
