"use client";

import { useEffect } from "react";

export function useScrollEffect() {
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        document.body.classList.remove("scrolled-down");
      } else {
        document.body.classList.add("scrolled-down");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
}
