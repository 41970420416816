import { ReactNode, useEffect, useState } from "react";

// This is used when having trouble with hydrating
// https://traviswimer.com/blog/easily-fix-react-hydration-errors#solution-1:-remove-content-from-initial-render
export function NoFirstRender({ children }: { children: ReactNode }) {
  const [hydrated, setHydrated] = useState(false);
  useEffect(() => {
    // This forces a rerender, so the node is rendered
    // the second time but not the first
    setHydrated(true);
  }, []);
  if (!hydrated) {
    // Returns null on first render, so the client and server match
    return null;
  }

  return children;
}
