"use client";

import { MExternalLink } from "@/components/MLink/MExternalLink";
import { MLink } from "@/components/MLink/MLink";
import { usePathname } from "@/lib/navigation";
import clsx from "clsx";
import { useLocale } from "next-intl";

export function NavLink({
  href,
  label,
  ariaLabel,
  className,
  isExternal = false,
}: {
  href: string;
  label: string;
  ariaLabel: string;
  className?: string;
  isExternal: boolean;
}) {
  const pathname: string = usePathname();
  const locale: string = useLocale();
  return (
    <li
      className={clsx(
        className,
        pathname.includes(href) ? "show" : "",
        "hover:text-darkBlue80 dark:hover:bg-primaryBlack dark:hover:text-darkBlue40",
        "flex h-full justify-center whitespace-nowrap transition-all ease-in-out hover:bg-lightestGrey",
      )}
    >
      {isExternal ? (
        <MExternalLink
          ariaLabel={ariaLabel}
          href={href}
          className={clsx(
            pathname.includes(href) ? "active" : "",
            "navActiveEffect self-center md:px-5 md:py-8 desktopNavFullsize:p-8",
          )}
          locale={locale}
          label={label}
        />
      ) : (
        <MLink
          ariaLabel={ariaLabel}
          href={href}
          className={clsx(
            pathname.includes(href) ? "active" : "",
            "navActiveEffect self-center md:px-5 md:py-8 desktopNavFullsize:p-8",
            "text-xl",
          )}
          locale={locale}
        >
          {label}
        </MLink>
      )}
    </li>
  );
}
