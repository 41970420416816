import { SVGProps, Ref, forwardRef, memo } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    ref={ref}
    {...props}
  >
    <mask id="a" fill="#fff">
      <path
        fillRule="evenodd"
        d="M11 12.357c0-.408.223-.779.552-1.02a6 6 0 1 0-7.103 0c.328.241.551.612.551 1.02 0 .631.512 1.143 1.143 1.143h3.714c.631 0 1.143-.512 1.143-1.143Z"
        clipRule="evenodd"
      />
    </mask>
    <path
      fill="#FFC20E"
      fillRule="evenodd"
      d="M11 12.357c0-.408.223-.779.552-1.02a6 6 0 1 0-7.103 0c.328.241.551.612.551 1.02 0 .631.512 1.143 1.143 1.143h3.714c.631 0 1.143-.512 1.143-1.143Z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      d="m4.448 11.336-.592.806.592-.806Zm7.104 0 .592.806-.592-.806ZM13 6.5a4.992 4.992 0 0 1-2.041 4.03l1.185 1.612A6.992 6.992 0 0 0 15 6.5h-2Zm-5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm-5 5a5 5 0 0 1 5-5v-2a7 7 0 0 0-7 7h2Zm2.041 4.03A4.992 4.992 0 0 1 3 6.5H1a6.992 6.992 0 0 0 2.856 5.642l1.185-1.611Zm4.816 1.97H6.143v2h3.714v-2ZM4 12.357c0 1.183.96 2.143 2.143 2.143v-2A.143.143 0 0 1 6 12.357H4Zm-.144-.215c.122.09.144.182.144.215h2c0-.784-.424-1.432-.959-1.826l-1.185 1.611Zm6 2.358c1.184 0 2.144-.96 2.144-2.143h-2a.143.143 0 0 1-.143.143v2Zm1.103-3.97c-.535.395-.959 1.043-.959 1.827h2c0-.033.022-.125.144-.215l-1.185-1.611Z"
      mask="url(#a)"
    />
    <path
      fill="#F08517"
      fillRule="evenodd"
      d="M11 12.357c0-.408.223-.779.552-1.02a6 6 0 1 0-7.103 0c.328.241.551.612.551 1.02 0 .631.512 1.143 1.143 1.143h3.714c.631 0 1.143-.512 1.143-1.143ZM13 6.5a4.992 4.992 0 0 1-2.041 4.03c-.535.395-.959 1.043-.959 1.827a.143.143 0 0 1-.143.143H8.5V9.707l2.354-2.353a.5.5 0 0 0-.708-.708L8 8.793 5.854 6.646a.5.5 0 1 0-.708.708L7.5 9.707V12.5H6.143A.143.143 0 0 1 6 12.357c0-.784-.424-1.432-.959-1.826A5 5 0 1 1 13 6.5Z"
      clipRule="evenodd"
    />
    <path
      fill="#092A43"
      fillRule="evenodd"
      d="M5 15a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5A.5.5 0 0 1 5 15Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const Memo = memo(ForwardRef);
export { Memo as LightOn };
