import { Combobox, ComboboxProps, InputBase, useCombobox } from "@mantine/core";
import { ReactNode } from "react";

type OptionType = {
  label: string | ReactNode;
  value: string | number;
};

export function Dropdown({
  options,
  onChange,
  selectedValue,
  ...props
}: {
  options: OptionType[];
  onChange: (value: string) => void;
  selectedValue?: string;
} & ComboboxProps) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const comboboxOptions = options.map(({ label, value }) => (
    <Combobox.Option
      aria-label={value.toString()}
      value={value.toString()}
      key={value}
    >
      {label}
    </Combobox.Option>
  ));

  function decoratedOnChange(value: string) {
    combobox.closeDropdown();
    onChange(value);
  }

  return (
    <div
      className="cursor-pointer"
      aria-label="Change language"
      onMouseDown={() => combobox.toggleDropdown()}
      onFocus={() => combobox.openDropdown()}
      onBlur={() => combobox.closeDropdown()}
    >
      <Combobox
        store={combobox}
        withinPortal={false}
        onOptionSubmit={decoratedOnChange}
        transitionProps={{
          duration: 200,
          transition: "pop-top-left",
        }}
        width={"100%"}
        classNames={{
          dropdown:
            "bg-darkBlue20 dark:bg-darkBlue dark:text-white rounded-ee-md rounded-es-md border-t-darkBlue40 border-t dark:border-none text-base",
          option:
            "flex p-3 justify-center hover:bg-darkBlue40 dark:hover:bg-darkBlue80 aria-selected:bg-darkBlue40 dark:aria-selected:bg-darkBlue80 capitalize rounded-md",
        }}
        {...props}
      >
        <Combobox.Target>
          <InputBase
            classNames={{
              root: "h-full",
              input: "w-4 uppercase",
              wrapper: `flex justify-center h-full dark:border dark:border-darkBlue p-6 gap-4 items-center transition-all ease-in-out dark:hover:bg-primaryBlack dark:hover:text-darkBlue40 hover:bg-lightestGrey hover:text-darkBlue80 hover:stroke-darkBlue80 dark:hover:stroke-darkBlue40 rounded-se-md ${
                combobox.dropdownOpened
                  ? "bg-lightestGrey text-darkBlue80 stroke-darkBlue80 dark:bg-primaryBlack dark:text-darkBlue40 dark:stroke-darkBlue40"
                  : "dark:stroke-white stroke-black rounded-ee-md"
              }`,
            }}
            component="button"
            type="button"
            rightSection={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
              >
                <path d="M1 1L6 6L11 1" strokeWidth="2" />
              </svg>
            }
            pointer
            rightSectionPointerEvents="none"
          >
            {selectedValue}
          </InputBase>
        </Combobox.Target>

        <Combobox.Dropdown>
          <Combobox.Options>{comboboxOptions}</Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </div>
  );
}
