"use client";
import { useState, useEffect, Dispatch } from "react";
import clsx from "clsx";
import { NoFirstRender } from "./NoFirstRender";
import { LightOn } from "../icons/LightOn";
import { LightOff } from "../icons/LightOff";
import { TinyArrow } from "../icons/TinyArrow";
import { useScrollEffect } from "@/lib/useScrollEffect";

declare global {
  interface Window {
    __setPreferredTheme: (theme: string) => void;
    __theme?: string;
    __onThemeChange: Dispatch<string>;
  }
}

export function ModeSwitch() {
  const [theme, setTheme] = useState(global.window?.__theme || "dark");
  const isDark = theme === "dark";
  useScrollEffect();
  const toggleTheme = () => {
    global.window?.__setPreferredTheme(theme === "light" ? "dark" : "light");
  };

  useEffect(() => {
    global.window.__onThemeChange = setTheme;
  }, []);

  return (
    <NoFirstRender>
      <div
        className={clsx(
          "mode-switch fixed -right-14 bottom-52 z-20 hidden -rotate-90",
          "md:block",
        )}
      >
        <div className="flex flex-row gap-5">
          <TinyArrow className="h-6 rotate-90" />
          <p>{"Scroll"}</p>
          <button className="flex gap-5" onClick={toggleTheme}>
            {isDark ? (
              <LightOff className="h-6" />
            ) : (
              <LightOn className="h-6" />
            )}
            {isDark ? (
              <span className="whitespace-nowrap">Turn lights on</span>
            ) : (
              <span className="whitespace-nowrap">Turn lights off</span>
            )}
          </button>
        </div>
      </div>
    </NoFirstRender>
  );
}
