"use client";
import { MLink } from "@/components/MLink/MLink";
import { Globe } from "@/components/icons/Globe";
import { LightsOn } from "@/components/icons/LightsOn";
import { ModityLogo } from "@/components/icons/ModityLogo";
import {
  TypeLanguage,
  TypeLanguageSkeleton,
} from "@/lib/contentful/contentfulTypes";
import { isNotUndefined } from "@/lib/contentful/isNotUndefined";
import { usePathname, useRouter } from "@/lib/navigation";
import { FocusTrap, Overlay } from "@mantine/core";
import clsx from "clsx";
import { Entry } from "contentful";
import { useLocale } from "next-intl";
import { PrefetchKind } from "next/dist/client/components/router-reducer/router-reducer-types";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";

export function MobileNavClient({
  children,
  lightsOn,
  languages,
  className,
}: {
  className?: string;
  lightsOff: string;
  lightsOn: string;
  children: ReactNode;
  languages: Entry<TypeLanguageSkeleton, "WITHOUT_LINK_RESOLUTION", string>[];
}) {
  const [showMenu, setShowMenu] = useState(false);
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);
  const path = usePathname();

  useEffect(() => {
    setShowMenu(false);
  }, [path]);

  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showMenu]);

  return (
    <FocusTrap active={showMenu && !showLanguageMenu}>
      <nav
        className={`fixed z-20 min-h-[60px] min-w-full bg-white backdrop-blur-sm dark:bg-darkBlue ${className}`}
      >
        <Overlay
          className={clsx(
            "absolute bottom-0 left-0 right-0 top-0 z-30 h-screen w-full backdrop-blur-sm",
            showLanguageMenu ? "block" : "hidden",
          )}
        />
        <div className="flex justify-between">
          <MLink
            href="/"
            className="flex items-center p-5"
            ariaLabel="Go to home page"
            onClick={() => setShowMenu(false)}
          >
            <ModityLogo width={85} height={20} />
          </MLink>
          <label
            className={clsx(
              "hamburgerMenu before:bg-primaryBlack after:bg-primaryBlack",
              "dark:before:bg-white dark:after:bg-white",
            )}
          >
            <input
              className="bg-primaryBlack dark:bg-white"
              type="checkbox"
              onChange={() => setShowMenu(!showMenu)}
              checked={showMenu}
            />
          </label>
        </div>
        {showMenu && (
          <div className="copyMedium flex min-h-[calc(100vh-60px)] flex-col justify-between font-light dark:bg-primaryBlack dark:text-white">
            {children}
            <MenuFooter
              languages={languages.filter(isNotUndefined)}
              lightsOn={lightsOn}
              showLanguageMenu={showLanguageMenu}
              setShowLanguageMenu={setShowLanguageMenu}
            />
          </div>
        )}
      </nav>
    </FocusTrap>
  );
}

function MenuFooter({
  languages,
  lightsOn,
  showLanguageMenu,
  setShowLanguageMenu,
}: {
  languages: TypeLanguage<"WITHOUT_UNRESOLVABLE_LINKS", string>[];
  lightsOn: string;
  showLanguageMenu: boolean;
  setShowLanguageMenu: Dispatch<SetStateAction<boolean>>;
}) {
  const locale = useLocale();
  const router = useRouter();
  const path = usePathname();

  return (
    <FocusTrap>
      <div className="copySmall z-30">
        <div className={showLanguageMenu ? "block" : "hidden"}>
          {languages.map((language) => (
            <button
              key={language.fields.language}
              onClick={() => {
                router.prefetch(path, {
                  locale: language.fields.locale,
                  kind: PrefetchKind.AUTO,
                });
                router.replace(path, { locale: language.fields.locale });
              }}
              className="block w-full px-7 py-2 text-right"
            >
              {language.fields.language}
            </button>
          ))}
        </div>

        <div className="flex items-end justify-between">
          <button
            className="flex items-end gap-2 px-4 py-3 "
            onClick={() => setShowLanguageMenu((value) => !value)}
          >
            <LightsOn />
            <label>{lightsOn}</label>
          </button>
          <button
            className="flex items-end gap-2 px-4 py-3"
            onClick={() => setShowLanguageMenu((value) => !value)}
          >
            <label>
              {
                languages.map((l) => l.fields).find((l) => l.locale === locale)
                  ?.language
              }
            </label>
            <Globe className="text-darkBlue80 dark:text-darkBlue40" />
          </button>
        </div>
      </div>
    </FocusTrap>
  );
}
