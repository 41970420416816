import { SVGProps, Ref, forwardRef, memo } from "react";

const SvgComponent = (
  { size, ...props }: SVGProps<SVGSVGElement> & { size?: Size },
  ref: Ref<SVGSVGElement>,
) => {
  const widthAndHeight = getSizeInPx(size);

  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      width={widthAndHeight}
      height={widthAndHeight}
      viewBox="0 0 40 40"
      ref={ref}
      {...props}
    >
      <path
        d="M2 19.5 h35 m0.2 0.9 L27.2 7 M37.2 18.6 L27.2 32"
        stroke="currentColor"
        strokeWidth="3"
      />
    </svg>
  );
};
const ForwardRef = forwardRef(SvgComponent);
const Memo = memo(ForwardRef);
export { Memo as ArrowRight };

function getSizeInPx(size: Size | undefined) {
  if (size === "XS") {
    return 24;
  }
  if (size === "S") {
    return 30;
  }
  if (size === "M") {
    return 40;
  }
  if (size === "L") {
    return 64;
  }
  // default to medium
  return 40;
}

type Size = "XS" | "S" | "M" | "L";
