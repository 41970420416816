"use client";

import { MLink } from "@/components/MLink/MLink";
import {
  TypeNavigationElementSkeleton,
  TypeExternalNavigationElementSkeleton,
} from "@/lib/contentful/contentfulTypes";
import clsx from "clsx";
import { Entry } from "contentful";
import { usePathname } from "next/navigation";

export function SubMenuTopLink({
  topLink,
}: {
  topLink: Entry<
    TypeNavigationElementSkeleton | TypeExternalNavigationElementSkeleton,
    "WITHOUT_LINK_RESOLUTION",
    string
  >;
}) {
  const pathname: string = usePathname();
  return (
    <MLink
      ariaLabel={topLink.fields.description}
      href={topLink.fields.url}
      className={clsx(
        pathname.includes(topLink.fields.url) ? "active" : "",
        "navActiveEffect self-center md:px-5 md:py-8 desktopNavFullsize:p-8",
        "text-xl",
        "peer",
        `peer/${topLink?.fields.label}`,
      )}
      locale={topLink.sys.locale}
      onClick={(e) => {
        e.currentTarget.blur();
      }}
    >
      {topLink.fields.label}
    </MLink>
  );
}
