import { SVGProps, Ref, forwardRef, memo } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    ref={ref}
    {...props}
  >
    <circle cx="12" cy="12" r="9.25" stroke="currentColor" strokeWidth="1.5" />
    <path
      stroke="currentColor"
      strokeWidth="1.5"
      d="M2.5 12h19M16.25 12c0 2.67-.543 5.05-1.385 6.736C14 20.466 12.944 21.25 12 21.25c-.944 0-2-.784-2.865-2.514C8.293 17.05 7.75 14.67 7.75 12s.543-5.05 1.385-6.736C10 3.534 11.056 2.75 12 2.75c.944 0 2 .784 2.865 2.514C15.707 6.95 16.25 9.33 16.25 12Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const Memo = memo(ForwardRef);
export { Memo as Globe };
