"use client";

import { MLink } from "@/components/MLink/MLink";
import { ArrowRight } from "@/components/icons/ArrowRight";
import clsx from "clsx";

export function ArrowLink({
  href,
  label,
  ariaLabel,
  size = "large",
  className,
}: {
  href: string;
  label: string;
  ariaLabel: string;
  size?: "small" | "large";
  className?: string;
}) {
  const isSmall = size === "small";
  return (
    <MLink
      ariaLabel={ariaLabel}
      className={clsx(
        className,
        "border-b-dark-blue group flex items-center justify-between border-b dark:border-b-darkBlue80",
        isSmall ? "p-5" : "p-10 max-sm:p-5",
        "hoverOrFocus:border-b-primaryOrange hoverOrFocus:text-primaryOrange",
      )}
      href={href}
      onClick={(e) => e.currentTarget.blur()}
    >
      <span>{label}</span>
      <div
        className={clsx(
          "invisible group-hoverOrFocus:visible",
          isSmall ? "h-[40px] w-[40px]" : "h-[64px] w-[64px]",
        )}
      >
        <ArrowRight size={isSmall ? "M" : "L"} />
      </div>
    </MLink>
  );
}
