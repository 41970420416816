import { MouseEventHandler, ReactNode } from "react";
import { Link } from "@/lib/navigation";

export function MLink({
  href,
  ariaLabel,
  className,
  children,
  locale,
  onClick,
}: {
  href: string;
  ariaLabel: string;
  className?: string;
  children: ReactNode;
  locale?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}) {
  return (
    <Link
      locale={locale as "en" | "sv" | undefined}
      href={href}
      aria-label={ariaLabel}
      className={className}
      onClick={onClick}
    >
      {children}
    </Link>
  );
}
