"use client";
import { useLocale } from "next-intl";
import { Dropdown } from "../Dropdown/Dropdown";
import { Entry } from "contentful";
import { TypeLanguageSkeleton } from "@/lib/contentful/contentfulTypes";
import { usePathname, useRouter } from "@/lib/navigation";

export function LanguageSwitch({
  languages,
}: {
  languages: Entry<
    TypeLanguageSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    string
  >[];
}) {
  const locale = useLocale();
  const router = useRouter();
  const path = usePathname();

  function changeLang(lang: string) {
    router.replace(path, { locale: lang });
    /**
     * To get dark mode to work properly when changing languages we need to
     * reload the page. This is because:
     *
     * 1. Dark mode with a toggle and keeping
     * SSG is quite hard in nextjs, thus we have implemented a "hacky" way of
     * getting it to work. Specifically
     * https://github.com/vercel/next.js/discussions/53063#discussioncomment-6996549
     *
     * 2. Internationalization requires us to reload the router with a lang.
     * This causes next to change just what is needed. Since the dark mode is
     * set by a script tag in the head that only runs when the page loads for
     * the first time, we fail to set the dark mode attribute in the HTML tag.
     * This is due to the next router does a "smart" way of changing the route
     * and returns the html for that url and saves the components. Thus causing
     * the script to not load
     *
     * To solve this the easiset way is to do a hard reload when we switch
     * languages so we get the script in the head to reload.
     */
    location.replace(path);
  }

  const localeOptions = languages.map((lang) => ({
    label: lang.fields.language,
    value: lang.fields.locale,
  }));

  return (
    <Dropdown
      options={localeOptions}
      selectedValue={locale}
      onChange={changeLang}
    />
  );
}
