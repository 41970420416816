import { SVGProps, Ref, forwardRef, memo } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    ref={ref}
    {...props}
  >
    <g fillRule="evenodd" clipRule="evenodd">
      <path
        fill="#9DAAB4"
        d="M11 12.357c0-.408.223-.779.552-1.02a6 6 0 1 0-7.103 0c.328.241.551.612.551 1.02 0 .631.512 1.143 1.143 1.143h3.714c.631 0 1.143-.512 1.143-1.143ZM13 6.5a4.992 4.992 0 0 1-2.041 4.03c-.535.395-.959 1.043-.959 1.827a.143.143 0 0 1-.143.143H8.5V9.707l2.354-2.353a.5.5 0 0 0-.708-.708L8 8.793 5.854 6.646a.5.5 0 1 0-.708.708L7.5 9.707V12.5H6.143A.143.143 0 0 1 6 12.357c0-.784-.424-1.432-.959-1.826A5 5 0 1 1 13 6.5Z"
      />
      <path
        fill="#3A5569"
        d="M5 15a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5A.5.5 0 0 1 5 15Z"
      />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const Memo = memo(ForwardRef);
export { Memo as LightOff };
