import(/* webpackMode: "eager", webpackExports: ["MantineProvider"] */ "/app/node_modules/@mantine/core/esm/core/MantineProvider/MantineProvider.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ArrowLink"] */ "/app/src/components/ArrowLink/ArrowLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModeSwitch"] */ "/app/src/components/ModeSwitch/ModeSwitch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavLink"] */ "/app/src/components/Nav/DesktopNav/NavLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SubMenuTopLink"] */ "/app/src/components/Nav/DesktopNav/SubMenuTopLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageSwitch"] */ "/app/src/components/Nav/LanguageSwitch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileNavClient"] */ "/app/src/components/Nav/MobileNavClientComponents/MobileNavClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileSubMenu"] */ "/app/src/components/Nav/MobileNavClientComponents/MobileSubMenu.tsx");
