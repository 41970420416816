import createMiddleware from "next-intl/middleware";

export const defaultLocale = "sv";
export const locales = ["sv", "en"];

export default createMiddleware({
  locales,
  defaultLocale,
});

export const config = {
  // Skip all paths that should not be internationalized
  matcher: ["/((?!api|_next|_vercel|.*\\..*).*)"],
};
