"use client";
import { ChevronUp } from "@/components/icons/ChevronUp";
import { ReactNode, useState } from "react";

export function MobileSubMenu({
  children,
  title,
}: {
  children: ReactNode;
  title: string;
}) {
  const [open, setOpen] = useState(false);
  return (
    <li>
      <button
        onClick={() => setOpen((value) => !value)}
        className="flex min-w-full items-center justify-between p-5"
      >
        <p>{title}</p>
        <ChevronUp
          className={`transform transition-transform ${
            open ? "rotate-0" : "rotate-180"
          }`}
        />
      </button>
      {open && children}
    </li>
  );
}
